$(function() {
  var modal = $('#modal-container');
  var myPlayer = videojs('#artagonist-video');

  $('.video-play').on("click", function(){
    var buttonId = $(this).attr('id');
    modal.removeAttr('class').addClass(buttonId);
    $('body').addClass('modal-active');
    myPlayer.ready(function() {
      myPlayer.play();
    });
  })
  
  $('.modal-top').on("click",function(){
    $('#modal-container').addClass('out');
    $('body').removeClass('modal-active');
    myPlayer.ready(function() {
      myPlayer.play();
      myPlayer.pause();
    });
  });

  myPlayer.on('ended', function() {
    $('body').removeClass('modal-active');
    $('#modal-container').addClass('out');
    myPlayer.pause();
  });

  $(window).on('resize orientationchange', function(){
    console.log("changed");
    $('body').removeClass('modal-active');
    $('#modal-container').addClass('out');
    myPlayer.pause();
    });  
});
